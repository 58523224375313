/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 09.08.22
 */

export default {
    // apiBaseUrl: 'https://varyada.cz/wp-json/eboard/api', // varyada
    apiBaseUrl: 'https://centralmost.cz/wp-json/eboard/api', // centralmost
    // apiBaseUrl: 'http://varyada.m31dev.cz/wp-json/eboard/api', // varyada (dev)
    endpoint: {
        login: '/authorization/login',
        postDetail: '/posts/{postSlug}',
        pageDetail: '/page/{pageSlug}',
        offerDetail: '/offers/{offerSlug}',
        shopDetail: '/shops/{shopSlug}'
    }
}
