/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 11.08.22
 */

import React from 'react';
import parse from 'html-react-parser';

class NewsHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {post} = this.props;

        return (
            <div className={'post-news-header'}>
                <div className={'post-news-header__background'}></div>
                {post.thumbnail &&
                    parse(post.thumbnail)
                }
            </div>
        )
    }
}

export default NewsHeader;
