/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 09.08.22
 */
import React from 'react';
import {axiosInstance} from '../api/axiosInstance';
import {getApiEndpoint} from '../api/apiHelper';
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import {useParams} from 'react-router';

class Homepage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            post: null,
            isLoaded: false,
            contentLoaded: false
        }
    }

    componentDidMount() {
        axiosInstance.get(getApiEndpoint('postDetail', {postSlug: 'device-homepage'}))
            .then((response) => {
                if(response.status === 200) {
                    if(response.data.data.post) {
                        this.setState({post: response.data.data.post, isLoaded: true});
                        setTimeout(() => {
                            this.setState({contentLoaded: true});
                        },500);
                    }
                }
            }).catch((error) => {
            console.log(error);
        })

        window.addEventListener('click', this.handleClick.bind(this));
        window.wp = window.wp || {};
    }

    checkIfFile(url) {
        return url.pathname.split('/').pop().indexOf('.') > 0;
    }

    handleClick(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let anchor = e.target.closest('a');


        if(anchor !== null) {
            let link = anchor.getAttribute('href');

            if(!link.startsWith('http')) {
                link = window.location.origin + link;
            }

            let url = new URL(link);
            let allowedHostnames = [
                'localhost',
                'varyada.m31dev.cz',
                'centralmost.m31dev.cz',
                'varyada.cz',
                'centralmost.cz',
                'clv.centralmost.cz',
                'clv.varyada.cz'
            ]

            if(allowedHostnames.includes(url.hostname)) {
                if(!this.checkIfFile(url)) {
                    window.location.href = url.pathname;
                }
            }
        }

        // e.preventDefault();
        // e.stopImmediatePropagation();
        // let anchor = e.target.closest('a');
        // if(anchor !== null) {
        //     let url = new URL(anchor.getAttribute('href'));
        //     window.location.href = url.pathname;
        // }
    }

    render() {
        let {isLoaded, post, contentLoaded} = this.state;

        let contentStyles = contentLoaded ? {opacity:  '1', transition: 'all 0.2s'} : {opacity: '0', transition: 'all 0.2s'};

        if(!isLoaded) {
            return (
                <div className={'is-loading'}></div>
            )
        }

        return (
            <React.Fragment>
                <Helmet>
                    <body className={post.body_class + ' eboard-homepage'} />
                </Helmet>

                {post.styles_array.map((style, index) => {
                    return <Helmet key={'style-'+index}>
                        <link rel={'stylesheet'} href={style} />
                    </Helmet>
                })}
                {post.inline_styles.map((inline_style, index) => {
                    return <Helmet key={'inline-style-'+index}>
                        <style>{inline_style}</style>
                    </Helmet>
                })}
                {post.inline_scripts.map((inline_script, index) => {
                    if(inline_script.includes("wp.i18n") || inline_script.includes('window._wpemojiSettings') || inline_script.includes('jetMenu')  || inline_script.includes('jetHamburger')) {
                        return;
                    }
                    return <Helmet key={'inline-script-'+index}>
                        <script type={'text/javascript'}>{inline_script}</script>
                    </Helmet>
                })}
                {post.scripts_array.map((script, index) => {
                    if(script.includes('jet-menu') || script.includes('jquery-migrate') || script.includes('maps.googleapis.com')) {
                        return;
                    }
                    return <Helmet key={'script-'+index}>
                        <script type={'text/javascript'} src={script} />
                    </Helmet>
                })}
                <div style={contentStyles}>
                    {parse(post.elementor_content)}
                </div>
            </React.Fragment>
        )
    }
}

export default (props) => (<Homepage {...props} params={useParams()} />);
