/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 11.08.22
 */

import React from 'react';
import parse from 'html-react-parser';
import {Parallax, ParallaxProvider} from 'react-scroll-parallax';

class BlogHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {post} = this.props;

        return (
            <ParallaxProvider>
                <div className={'post-blog-header'}>
                    <div className={'elementor-section elementor-section-boxed'}>
                        <div className={'elementor-container'}>
                            <h1>{post.post_title}</h1>
                        </div>
                    </div>
                    <div className={'post-blog-header__thumbnail'}>
                        <Parallax speed={10}>
                            {post.thumbnail &&
                                parse(post.thumbnail)
                            }
                        </Parallax>
                    </div>
                </div>
            </ParallaxProvider>
        )
    }
}

export default BlogHeader;
