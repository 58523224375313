/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 09.08.22
 */

const LocalStorageService = (function() {
    let _service;

    function _getService() {
        if(!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setToken(access_token) {
        localStorage.setItem('access_token', access_token);
    }

    function _setDeviceId(deviceId) {
        localStorage.setItem('device_id', deviceId);
    }

    function _getAccessToken() {
        return localStorage.getItem('access_token');
    }

    function _getDeviceId() {
        return localStorage.getItem('device_id');
    }

    function _clearToken() {
        localStorage.removeItem('access_token');
    }

    function _clearDeviceId() {
        localStorage.removeItem('device_id');
    }

    return {
        getService : _getService,
        setToken : _setToken,
        getAccessToken : _getAccessToken,
        clearToken : _clearToken,
        setDeviceId : _setDeviceId,
        getDeviceId : _getDeviceId,
        clearDeviceId : _clearDeviceId,
    }
})();

export default LocalStorageService;
