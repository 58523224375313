/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 10.08.22
 */

import React from 'react';
import {axiosInstance} from '../api/axiosInstance';
import {getApiEndpoint} from '../api/apiHelper';
import {Helmet} from "react-helmet";
import parse from 'html-react-parser';
import {useParams, useNavigate} from 'react-router';
import NewsHeader from './parts/NewsHeader';
import BlogHeader from './parts/BlogHeader';
import OfferHeader from './parts/OfferHeader';
import {Link} from 'react-router-dom';
import SVG from 'react-inlinesvg';
import iconBack from '../assets/img/arrow-left-solid.svg';
import iconHome from '../assets/img/house-solid.svg';

class Post extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            post: null,
            isLoaded: false,
            hasError: false,
            contentLoaded: false
        }
    }

    componentDidMount() {
        let {slug} = this.props.params;
        if(!this.state.isLoaded) {
            axiosInstance.get(getApiEndpoint('postDetail', {postSlug: slug}))
                .then((response) => {
                    if(response.status === 200) {
                        if(response.data.data.post) {
                            this.setState({post: response.data.data.post, isLoaded: true});
                            setTimeout(() => {
                                this.setState({contentLoaded: true});
                                window.dispatchEvent(new Event('resize'));
                            },500);
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                    this.setState({isLoaded: true, hasError: true});
            })
            window.addEventListener('click', this.handleClick.bind(this));
        }

    }

    checkIfFile(url) {
        return url.pathname.split('/').pop().indexOf('.') > 0;
    }

    handleClick(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let anchor = e.target.closest('a');
        let post = this.state.post;


        if(anchor !== null) {
            let link = anchor.getAttribute('href');

            if(link === '#') {
                return;
            }

            if(!link.startsWith('http')) {
                link = window.location.origin + link;
            }


            let url = new URL(link);
            let allowedHostnames = [
                'localhost',
                'varyada.m31dev.cz',
                'centralmost.m31dev.cz',
                'varyada.cz',
                'centralmost.cz',
                'clv.centralmost.cz',
                'clv.varyada.cz'
            ]

            if(allowedHostnames.includes(url.hostname) && post.post_type === 'eboard_post') {
                if(!this.checkIfFile(url)) {
                    window.location.href = url.pathname;
                }
            }
        }

        // if(anchor !== null) {
        //     let link = anchor.getAttribute('href');
        //     if(link.startsWith('http')) {
        //         let url = new URL(anchor.getAttribute('href'));
        //         let target = anchor.getAttribute('target');
        //
        //         if(target === '_blank') {
        //             window.location.href = url.href;
        //         }else{
        //             window.location.href = url.pathname;
        //         }
        //     }else{
        //         if(link !== '#') {
        //             window.location.href = link;
        //         }
        //     }
        // }
    }

    render() {
        let {isLoaded, hasError, post, contentLoaded} = this.state;

        if(!isLoaded) {
            return (
                <div className={'is-loading'}></div>
            )
        }

        if(hasError) {
            return <>
                <div className={'error'}>
                    <p>Obsah nelze zobrazit.</p>
                    <p>Zkuste to prosím později.</p>
                    <button className={'reload-btn'} onClick={() => window.location.reload()}>Obnovit stránku</button>
                </div>
                <div className={'eboard-nav-buttons'}>
                    <button type={'button'} onClick={() => this.props.navigate(-1)}><SVG src={iconBack} /></button>
                    <Link to={'/'}><SVG src={iconHome} /></Link>
                </div>
                </>
        }

        let postCategory = post.category ? post.category[0].term_id : null;
        let styles = contentLoaded ? {opacity:  '1', transition: 'all 0.2s'} : {opacity: '0', transition: 'all 0.2s'};

        return (
            <React.Fragment>
                <Helmet>
                    <body className={post.body_class} />
                </Helmet>

                <div style={styles}>
                    {postCategory === 64 &&
                        <NewsHeader post={post} />
                    }

                    {postCategory === 54 &&
                        <BlogHeader post={post} />
                    }

                    {post.post_type === 'sc_offer' &&
                        <OfferHeader post={post} />
                    }
                </div>

                <div className={'eboard-post-content'} style={styles}>
                    {parse(post.elementor_content)}

                    {post.post_type === 'sc_shop' &&
                        parse(post.opening_hours)
                    }

                    <div className={'eboard-nav-buttons'}>
                        <button type={'button'} onClick={() => this.props.navigate(-1)}><SVG src={iconBack} /></button>
                        <Link to={'/'}><SVG src={iconHome} /></Link>
                    </div>
                </div>

                {post.styles_array.map((style, index) => {
                    return <Helmet key={'style-'+index}>
                        <link rel={'stylesheet'} href={style} />
                    </Helmet>
                })}
                {post.inline_styles.map((inline_style, index) => {
                    return <Helmet key={'inline-style-'+index}>
                        <style>{inline_style}</style>
                    </Helmet>
                })}
                {post.inline_scripts.map((inline_script, index) => {
                    if(inline_script.includes("wp.i18n") || inline_script.includes('window._wpemojiSettings') || inline_script.includes('jetMenu')  || inline_script.includes('jetHamburger')) {
                        return;
                    }
                    return <Helmet key={'inline-script-'+index}>
                        <script type={'text/javascript'} key={'inline-script-'+index}>{inline_script}</script>
                    </Helmet>
                })}
                {post.scripts_array.map((script, index) => {
                    if(script.includes('jet-menu') || script.includes('jquery-migrate') || script.includes('maps.googleapis.com')) {
                        return;
                    }
                    return <Helmet key={'script-'+index}>
                        <script type={'text/javascript'} src={script} />
                    </Helmet>
                })}
            </React.Fragment>
        )
    }
}

export default (props) => (<Post {...props} params={useParams()} navigate={useNavigate()} />);
