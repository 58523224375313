/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 11.08.22
 */

import React from 'react';
import parse from 'html-react-parser';

class OfferHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {post} = this.props;

        return (
            <div className={'post-offer-header'}>
                {post.thumbnail &&
                    parse(post.thumbnail)
                }
                {post.offer_headline &&
                    parse(post.offer_headline)
                }
            </div>
        )
    }
}

export default OfferHeader;
